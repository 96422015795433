<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="고객사 정보"></CarrotTitle>
        <div class="board">
            <div class="mt-40"> 
                <div class="tabDefult-col">
                    <router-link :to="{ name:'customerManagement-customerDBViewTab1-idx', params:{ idx:view.idx } }"><button class="tab-default">정보</button></router-link>
                    <router-link :to="{ name:'customerManagement-customerDBViewTab2-idx', params:{ idx:view.idx } }"><button class="tab-default">고객사일지</button></router-link>
                    <router-link :to="{ name:'customerManagement-customerDBViewTab3-idx', params:{ idx:view.idx } }"><button class="tab-default on">명함</button></router-link>
                </div>
                <div>
                    <router-link :to="{ name:'customerManagement-businessCardAdd' }"><button class="btn-default float-right mb-20">명함등록</button></router-link>

                    <table class="table-col">
                        <colgroup>
                            <col width="130">
                            <col width="120">
                            <col width="130">
                            <col width="130">
                            <col width="110">
                            <col width="150">
                            <col width="*">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>담당자명</th>
                                <th>직급</th>
                                <th>Tel</th>
                                <th>Mobile</th>
                                <th>메인담당자</th>
                                <th>컨퍼런스 참석이력</th>
                                <th>이메일</th>
                                <th>매니저</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.list" :key="i">
                                <td><router-link :to="{ name:'customerManagement-businessCardView-idx', params: { idx:irow.idx } }" target="_blank"><span class="btn-view">{{ irow.kname }}</span></router-link></td>
                                <td>{{ irow.position }}</td>
                                <td>{{ irow.tel }}</td>
                                <td>{{ irow.mobile }}</td>
                                <td>{{ irow.is_main }}</td>
                                <td>{{ irow.is_conference }}</td>
                                <td>{{ irow.email }}</td>
                                <td>{{ irow.hq_ename }}({{ irow.hq_kname }})</td>
                            </tr>
                            <tr v-if="view.total==0">
                                <td colspan="8">등록된 명함이 없습니다.</td>
                            </tr>
                            <!-- <tr>
                                <td><router-link to="/scheduleOffSiteView"><span class="btn-view">김민정</span></router-link></td>
                                <td>사원</td>
                                <td>02-0000-0000</td>
                                <td>010-1234-1234</td>
                                <td>Y</td>
                                <td>N</td>
                                <td>abc@abc.kr</td>
                                <td>Min K(김민정)</td>
                            </tr> -->
                        </tbody>
                    </table>
                    <CarrotPaging :total="view.total" :list_per_page="view.rows" v-model="view.page" @change="view.doSearch"></CarrotPaging>

                    <button class="btn-default float-left mt-30" @click="view.goList">목록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'


export default {
    layout:"customerManagement",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const view = reactive({
            idx : 0,
            info : {},

            page : 1, 
            rows : 10,
            list : {}, total : 0,

            goList : () => {
                router.push({
                    name : "customerManagement-customerDBList"
                });
            },

            doSearch : () => {
                if( view.idx == 0 ) return;

                let params = {
                    page : view.page,
                    rows : view.rows,
                    idx  : view.idx
                };

                axios.get("/rest/customermgr/getCustomerBizcards", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.total = res.data.total;
                        view.list  = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearchInfo : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/customermgr/getCustomerDBInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;
                        view.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearchInfo();
        });

        return {view};
    }
}
</script>

<style lang="scss" scoped>
</style>